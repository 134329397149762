<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row class="mt-1_ match-height_">

            <!-- Alert Rotations -->
            <b-col v-if="isRecalculateRotations || isErrorOnRecalculationRotation" cols="12">
                <b-alert :variant="isRecalculateRotations && isEndRecalculation ? 'success' : (isErrorOnRecalculationRotation ? 'danger' : 'warning')" show>
                    <div class="alert-body">
                        <feather-icon icon="InfoIcon" class="mr-50"/>
                        <span v-if="!isErrorOnRecalculationRotation">
                            <strong>
                                {{ isRecalculateRotations && isEndRecalculation ? 'Recalculo Finalizado' : 'Recalculando Rotaciones.... Por favor no cierre ni actualice la página hasta terminar el proceso.' }} 

                                <b-spinner v-if="isRecalculateRotations && !isEndRecalculation" small variant="warning" class="mr-1_" type="grow"/>
                            </strong>
                            <span v-if="isRecalculateRotations && isEndRecalculation">
                                ¿Desea actualizar los registros actuales?
                                <b-button @click="acceptUpdatingCurrentData" size="sm" class="ml-1" variant="warning">Si Actualizar</b-button>
                                <b-button @click="cancelUpdatingCurrentData" size="sm" class="ml-1" variant="secondary">No, Cancelar</b-button>
                            </span>
                        </span>
                        <span v-else>
                            <strong>Se genero un error al recalcular las rotaciones.... Por favor intente más tarde.</strong>
                        </span>
                    </div>
                </b-alert>
            </b-col>

            <!-- Col: Left (Purchase Container Filters ) -->
            <b-col cols="12">                
                <b-card-actions noActions>
                    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                        <b-form class="p-2_" ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                            <b-row>
                                <b-col  cols="12" xl="9" md="8">
                                    <b-row>
                                        <!-- Almacenes -->
                                        <b-col cols="12" md="4">
                                            <validation-provider #default="{ errors }" name="Almacén" rules="required">
                                                <b-form-group label="Almacén" description="Selecciona una almacén" label-for="almacen_Id"
                                                    :state="errors.length > 0 ? false : null">
                                                    <v-select class="select-size-sm" id="almacen_Id" v-model="purchaseData.almacen_Id" :options="warehouses"
                                                        :reduce="option => option.almacen_Id" label="nombre"
                                                        placeholder="Selecciona una opción" @input="selectWarehouse" :clearable="false"
                                                        :searchable="false"
                                                        size="sm">
                                                    </v-select>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <!-- Filtro by -->
                                        <b-col cols="12" md="4">
                                            <b-form-group label="Filtro Por" label-cols="12"
                                                label-align="left">
                                                <b-form-radio-group id="radio-group-1" v-model="optionFilterBy" :options="optionsFilters"
                                                    :reduce="option => option.value" name="radio-options-1" class="groupselect"></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- Parameters -->
                                        <b-col v-if="optionFilterBy === 'by_provider'" cols="12" md="4">
                                            <b-form-group label="Parámetros" label-cols="12"
                                                label-align="left">
                                                <b-form-radio-group id="radio-group-2" v-model="optionSupplierFilterBy" :options="optionsSupplierFilters"
                                                    :reduce="option => option.value" name="radio-options-2" class="groupselect"></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>     
                                    
                                    <b-row v-if="purchaseData.almacen_Id">

                                        <!-- Purchased from -->
                                        <b-col v-if="optionSupplierFilterBy === 'items_purchased_from'" cols="12" md="2">
                                            <b-form-group label="Fecha Desde" description="Selecciona una fecha" label-for="items_purchased_from">
                                                <flat-pickr id="buy_from" v-model="buyFrom" class="form-control flatpickr-small"                                                    
                                                :config="{ dateFormat: 'Y-m-d', }" placeholder="DD/MM/YYYY"/>
                                            </b-form-group>
                                        </b-col>
                                        
                                        <!-- Proveedores -->
                                        <b-col v-if="optionFilterBy === 'by_provider'" cols="12" md="8">
                                            <b-form-group label="Proveedores" description="Selecciona un proveedor" label-for="Proveedor_Id">
                                                <v-select class="select-size-sm" 
                                                    id="Proveedor_Id" v-model="purchaseData.supplier_id" :options="suppliers"
                                                    :reduce="option => option.proveedor_Id" label="nombre"
                                                    placeholder="Selecciona una opción" :clearable="false" :searchable="true">
                                                </v-select>
                                            </b-form-group>
                                        </b-col>

                                        

                                        <!-- Clasificadores -->
                                        <b-col v-if="optionFilterBy === 'by_classification'" cols="12" md="4">
                                            <b-form-group label="Clasificación" description="Selecciona una opción" label-for="classifier_Id">
                                                <v-select class="select-size-sm" id="classifier_Id" v-model="purchaseData.classifier_Id" :options="classifiers"
                                                    :reduce="option => option.clasificador_Id" label="nombre"
                                                    placeholder="Selecciona una opción" @input="selectClassifier" :clearable="false"
                                                    :searchable="false">
                                                </v-select>
                                            </b-form-group>
                                        </b-col>

                                        <!-- Valor de Clasificadores -->
                                        <b-col v-if="optionFilterBy === 'by_classification'" cols="12" md="3">
                                            <b-form-group label="Clasificación Valores" description="Selecciona una opción" label-for="valor_clasif_id">
                                                <v-select class="select-size-sm" id="valor_clasif_id" v-model="purchaseData.valor_clasif_id"
                                                    :options="classifierValues" :reduce="option => option.valor_Clasif_Id" label="valor"
                                                    placeholder="Selecciona una opción" :clearable="false" :searchable="false">
                                                </v-select>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" md="3">
                                            <b-button size="sm" @click="getProducts" variant="primary">
                                                <!-- <feather-icon icon="SearchIcon" /> -->
                                                Buscar
                                            </b-button>

                                            <b-button @click="copyTableFilters" size="sm" variant="info" class="ml-1">
                                                <!-- <feather-icon icon="CopyIcon" /> -->
                                                Copiar
                                            </b-button>

                                            <!-- <b-button @click="exportToExcel" variant="warning" class="mt-2 ml-1">
                                                <feather-icon icon="DownloadIcon" />
                                                Descargar
                                            </b-button> -->
                                        </b-col>
                                    </b-row>
                                </b-col>                                   
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-actions>

                <!-- Tab Pages -->
                <b-card class="mb-0 mt-1_">
                    <b-tabs class="my-custom-tabs" content-class="pt-1_" fill>
                        <!-- @click="getGeneralArticles" -->
                        <b-tab @click="getGeneralArticles" title="General">
                            <div class="table-responsive">
                                <div class="table-responsive">
                                    <!-- style="overflow-y: auto !important; max-height: calc(-200px + 100vh) !important;" -->
                                    <div ref="tableContainer" class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive" 
                                        >                                            
                                        <table role="table" aria-busy="false" aria-colcount="14" aria-multiselectable="false" 
                                            class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                            <thead role="rowgroup">
                                                <tr>
                                                    <th v-for="column in columnPurchases" :key="column.key" style="font-size: 0.7rem!important;">{{ column.label }}</th>                                                              
                                                </tr>
                                            </thead>
                                            <tbody role="rowgroup">
                                                <tr v-for="(item, index) in filterDataGeneal" :key="index" role="row">
                                                    <td v-for="column in columnPurchases" v-if="column.visible" :key="column.key">

                                                        <div v-if="column.key == 'nombre'">
                                                            <span v-if="canUpdatePrice">
                                                                <b-link @click="setArticle(item, index)" class="font-weight-bold d-block">
                                                                    {{ item[column.key] }}
                                                                </b-link>
                                                            </span>
                                                            <span v-else>
                                                                {{ item[column.key] }}
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            {{ item[column.key] }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        </b-tab>

                        <b-tab @click="actionTabGeneral = false" active title="Por Filtro">
                            <div class="table-responsive">
                                <b-table ref="refPurchasesListTable" 
                                    class="position-relative b-table-responsive mb-0 small"
                                    :items="filteredPurchaseList" 
                                    selectable
                                    select-mode="single" 
                                    responsive="sm"
                                    :fields="columnPurchases" 
                                    primary-key="articulo_Id" show-empty
                                    empty-text="No hay registros" :busy.sync="loading" small 
                                    :sticky-header="true" :no-border-collapse="false"                                        
                                    @row-clicked="toggleDetails"
                                    style="overflow-y: auto!important;max-height: calc(100vh - 200px)!important;">

                                    

                                    <!-- Empty -->
                                    <template slot="empty">
                                        <h6 class="text-center mt-1">
                                            No se encontraron registros
                                        </h6>
                                    </template>

                                    <!-- Column: Nombre -->
                                    <template #cell(nombre)="data">                                
                                        <span v-if="canUpdatePrice">
                                            <b-link @click="setArticle(data.item, data.index)" class="font-weight-bold d-block">
                                                {{ data.item.nombre }}
                                            </b-link>
                                        </span>
                                        <span v-else>
                                            {{ data.item.nombre }}
                                        </span>
                                    </template>

                                    <!-- Costo -->
                                    <template #cell(ultimo_Costo)="data">
                                        {{ formatCurrency(data.item.ultimo_Costo) }}
                                    </template>

                                    <!-- ventas_Pv -->
                                    <template #cell(rotacion_Pv)="data">
                                        {{ data.item.rotacion_Pv }}
                                    </template>

                                    <!-- ventas_Ventas -->
                                    <template #cell(rotacion_Ve)="data">
                                        {{ data.item.rotacion_Ve }}
                                    </template>

                                    <!-- devoluciones_Totales -->
                                    <template #cell(devoluciones_Totales)="data">
                                        {{ data.item.devoluciones_Totales }}
                                    </template>

                                    <!-- total MESES -->
                                    <template #cell(total_meses)="data">
                                        {{ numberFormat(data.item.total_meses) }}
                                    </template>

                                    <!-- venta_Proyectada -->
                                    <template #cell(rotacion_Pb_Ve_Proyectada)="data">
                                        {{ data.item.rotacion_Pb_Ve_Proyectada }}
                                    </template>

                                    <!-- Column: Fecha -->
                                    <template #cell(fecha_Actualizacion)="data">
                                        <span class="text-capitalize text-wrapp">{{ formatDate(data.item.fecha_Actualizacion) }}</span>
                                    </template>
                                </b-table>
                            </div>
                        </b-tab>

                        <!-- New Tab Input (Using tabs-end slot) -->
                        <template #tabs-end>
                            <b-nav-item role="presentation">
                                <b-input-group class="d-flex align-items-center justify-content-end w-100">
                                    <b-form-input ref="inputSearchItems" autofocus v-model="searchItemTemp" @keydown.enter="applySearch" size="sm" 
                                    class="d-inline-block" block placeholder="Buscar por clave o nombre articulo"/>
                                    <b-input-group-prepend>
                                        <b-button @click="applySearch" size="sm" variant="primary">
                                            <feather-icon icon="SearchIcon" />
                                        </b-button>

                                        <b-button @click="clearSearch" size="sm" variant="danger">
                                            <feather-icon icon="XIcon" />
                                        </b-button>

                                        <!-- <b-button @click="copyTableFilters" size="sm" variant="info"
                                            v-b-tooltip.hover.right="`Copiar`">
                                            <feather-icon icon="CopyIcon" />
                                        </b-button> -->
                                    </b-input-group-prepend>                                
                                </b-input-group>
                            </b-nav-item>
                        </template>
                    </b-tabs>
                </b-card>
                <!-- /.Tab Pages -->
               
            </b-col>
        </b-row>        

        <!-- Modal Prices -->
        <b-modal ref="priceModalRef" id="modalPrices" no-close-on-backdrop
            title-class="font-weight-bold" hide-header hide-footer @cancel="isActiveModal = false" 
            @hidden="closeModal" :visible="isActiveModal" size="xl" style="display: flex; justify-content: center; align-items: center;">

            <template #modal-title>
                <h5 class="mb-0"><strong>Detalle {{ article.nombre }}</strong></h5>
            </template>

            <b-overlay :show="loadingModal" blur="2px" variant="transparent" rounded="xl" opacity="0.85"> 

                <!-- Modal Content -->
                <b-row>
                    <b-col cols="12" md="8">
                        <h6 class="mb-0"><strong>Detalle {{ article.nombre }}</strong></h6>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-button-toolbar justify>

                            <!--group 1 -->
                            <b-button-group class="mr-2 mb-1">
                            
                            </b-button-group>

                            <!-- group 2 -->
                            <b-button-group class="mr-2 mb-1">
                                <b-button
                                    size="sm"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    @click="handlePreviousRow">
                                    <feather-icon icon="ChevronLeftIcon" />
                                    Anterior
                                </b-button>
                                <b-button size="sm"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    @click="handleNextRow">
                                    Siguiente
                                    <feather-icon icon="ChevronRightIcon" />
                                </b-button>

                                <b-button variant="outline-danger" size="sm" @click="closeModal">
                                    <feather-icon icon="XIcon" />
                                </b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </b-col>
                </b-row>


                <b-row class="match-height">
                    <b-col cols="12" md="6">
                        <b-card>
                            <b-tabs content-class="pt-1">
                                <b-tab @click="setTabOptions('change-prices')" lazy title="Cambio de Precios">
                                    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                                        <b-form class="p-2_" ref="formRef" @submit.prevent="handleSubmit(onSubmit)">
                                            <b-row>
                                                <b-col cols="12" md="8">
                                                    <b-form-group label="Clave Articulo" label-for="clave_Articulo">
                                                        <b-form-input size="sm" disabled id="clave_Articulo" v-model="article.clave_Articulo" />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="4">
                                                    <b-form-group label="Último Costo" label-for="ultimo_costo">
                                                        <b-form-input @input="calculatePrice" size="sm" id="ultimo_costo" type="number" step="any" v-model="article.ultima_Compra" />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-row v-for="(item, index) in priceLists" :key="index">
                                                <b-col cols="12" md="4">
                                                    <b-form-group :label="index === 0 ? 'Modificar' : ''" :label-for="`price_${index+1}`">
                                                        <b-form-checkbox v-model="item.selected" :id="`price_${index+1}`" class="mt-1">
                                                            <small>{{ item.name }}</small>
                                                        </b-form-checkbox>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="4">
                                                    <b-form-group :label="index === 0 ? 'Margen' : ''" :label-for="`margin_${index+1}`">
                                                        <b-form-input @input="setNewPrice(item)" size="sm" v-model="item.margin" :id="`margin_${index+1}`" type="number" step="any" class="mb-2" />
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="12" md="4">                                
                                                    <b-form-group :label="index===0 ? 'Precio' : ''" :label-for="`price_${index+1}`">
                                                        <b-form-input @input="calculateMargin" size="sm" v-model="item.price" :id="`price_${index+1}`" type="number" step="any" class="mb-2" />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <!-- Actions  -->
                                            <b-row>
                                                <b-col cols="12" class="text-right mt-2">
                                                    <b-button 
                                                        v-if="isEnabledButtonSavePrices"
                                                        type="submit"
                                                        :disabled="!priceLists.some(item => item.selected) || loading"
                                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
                                                        Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>                                                
                                        </b-form>
                                    </validation-observer>
                                </b-tab>

                                <b-tab @click="setTabOptions('get-purchases')" lazy title="Últimas Compras">
                                    <b-row>
                                        <b-col cols="12" md="8">
                                            <b-form-group label="Tipo Documento" label-for="document_type" label-class="font-weight-bold">
                                                <v-select 
                                                    v-model="type_document"
                                                    id="document_type"
                                                    :options="[
                                                        { value: 'R', label: 'Recepción'},
                                                        { value: 'C', label: 'Compras'}
                                                    ]"
                                                    :reduce="option => option.value"
                                                    name="label"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    class="select-size-sm"
                                                    @input="getLastPurcases">
                                                </v-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="4"></b-col>
                                    </b-row>
                                    <b-table :items="lastPurchases" responsive :fields="columnLastPurchases" 
                                        class="position-relative b-table-responsive mb-0_ small my-small text-small"
                                        :sticky-header="true" :no-border-collapse="true" small 
                                        show-empty 
                                        empty-text="No se encontraron registros"
                                        :busy.sync="loadingModal"
                                        style="max-height: 300px!important;"
                                        >

                                        <!-- Empty -->
                                        <template slot="empty">
                                            <h6 class="text-center mt-1">
                                                No se encontraron registros
                                            </h6>
                                        </template>

                                        <!-- Column: Costo -->
                                        <template #cell(costo)="data">
                                            {{ formatCurrency(data.item.costo) }}
                                        </template>

                                        <!-- Column: costoReal -->
                                        <template #cell(costoReal)="data">
                                            {{ formatCurrency(data.item.costoReal) }}
                                        </template>

                                        <!-- Column: Fecha -->
                                        <template #cell(fecha)="data">
                                            <span class="text-capitalize">{{ formatDate(data.item.fecha) }}</span>
                                        </template>
                                    </b-table>
                                </b-tab>

                                <b-tab @click="setTabOptions('get-kardex')" lazy title="Kardex">
                                    
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <b-form-group label="Mostrar" label-for="per_page" label-class="font-weight-bold">
                                                <v-select id="per_page" 
                                                    v-model="perPage" 
                                                    :options="perPageOptions" 
                                                    :reduce="option => option.value" 
                                                    :clearable="false"
                                                    name="label"
                                                    class="select-size-sm"
                                                    @input="selectWarehouseOnDetail" />
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" md="8">
                                            <b-form-group label="Almacén" label-for="new_almacen_Id" label-class="font-weight-bold">
                                                <v-select class="select-size-sm" id="new_almacen_Id"
                                                    v-model="currenWarehouseId"                                                    
                                                    :options="warehouses"
                                                    :reduce="option => option.almacen_Id" label="nombre"
                                                    placeholder="Selecciona una opción" 
                                                    :clearable="false"
                                                    :searchable="false"
                                                    size="sm"
                                                    @input="selectWarehouseOnDetail">
                                                </v-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="5"></b-col>
                                    </b-row>

                                    <!-- <b-table responsive :items="kardexes" :fields="columnKardex" 
                                        class="position-relative b-table-responsive mb-0_ small my-small text-small"
                                        :sticky-header="true" :no-border-collapse="true" small 
                                        show-empty 
                                        empty-text="No se encontraron registros"
                                        :busy.sync="loadingModal"
                                        >
                                            
                                            <template slot="empty">
                                                <h6 class="text-center mt-1">
                                                    No se encontraron registros
                                                </h6>
                                            </template>
                                            
                                            <template #cell(fecha)="data">
                                                <span class="text-capitalize">{{ formatDate(data.item.fecha) }}</span>
                                            </template>
                                        </b-table> -->
                                    <div class="position-relative b-table-responsive mb-0_ small small my-small text-small b-table-sticky-header table-responsive" >
                                        <table style="max-height: 300px!important;"  class="table table-responsive table b-table table-sm">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Fecha</th>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Concepto</th>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Folio</th>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Descripción</th>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Unidades</th>
                                                    <th class="text-nowrap" style="font-size: 8px!important;">Inv. Final</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="kardexes.length > 0">
                                                <tr v-for="(item, index) in kardexes" :key="index">
                                                    <td style="font-size: 10px!important;" class="text-nowrap text-capitalize">{{ formatDate(item.fecha) }}</td>
                                                    <td style="font-size: 10px!important;" class="text-nowrap">{{ item.concepto.trim() }}</td>
                                                    <td style="font-size: 10px!important;" class="text-nowrap">{{ item.folio }}</td>
                                                    <td style="font-size: 10px!important;" class="_text-wrap text-truncate" v-b-tooltip.hover.right="item.descripcion">{{ item.descripcion }}</td>
                                                    <td style="font-size: 10px!important;" class="text-nowrap">{{ item.unidades }}</td>
                                                    <td style="font-size: 10px!important;" class="text-nowrap">{{ item.inventario }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="7">
                                                        <h6 class="text-center mt-1"> No se encontraron registros </h6>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>  

                    <!-- Col: Other Connection -->
                    <b-col cols="12" md="6">
                        <b-card>
                            
                            <b-row>
                                <b-col cols="12" md="6">                                    
                                    <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                        <v-select id="idEmpresa"                                        
                                            class="select-size-sm"
                                            v-model="otherBranches.connection_id"
                                            :options="connections"
                                            :reduce="option => option.idEmpresa" 
                                            label="nombre" placeholder="Selecciona una opción"
                                            :clearable="false" :searchable="false"
                                            @input="selectConnection">
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group label="Sucursal" label-for="sucursal_Id" label-class="font-weight-bold">
                                        <v-select id="sucursal_Id" 
                                            class="select-size-sm"
                                            v-model="otherBranches.branch_id"
                                            :options="branches"
                                            :reduce="option => option.sucursal_Id" 
                                            label="nombre" placeholder="Selecciona una opción"
                                            :clearable="false" :searchable="false"
                                            @input="selectBranch">
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="2">
                                    <b-button :disabled="!otherBranches.connection_id || !otherBranches.branch_id" size="sm" @click="searchByTabOptions" variant="primary" class="mt-2">
                                        Buscar
                                    </b-button>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12">
                                    <h6 class="font-weight-bold _mt-1 _text-center">
                                        Detalle Articulo: <span v-if="otherBranches.article"><strong>{{ otherBranches.article.nombre }}</strong></span>
                                    </h6>
                                </b-col>
                            </b-row>
                            <hr>
                            <!-- Component by tab options -->

                            <prices-changes v-if="tabOptionSelected == 'change-prices' && otherBranches.article && otherBranches.connection_id && otherBranches.branch_id" 
                                :active="true" 
                                :article="article"
                                :connection="otherBranches.connection_id"
                                :branch="otherBranches.branch_id"
                                :warehouse="otherBranches.warehouse_id"
                                :prices="otherBranches.listPrices"
                                :message="otherBranches.listPricesMessage"/> 

                            <last-purchases v-if="tabOptionSelected == 'get-purchases' && otherBranches.article && otherBranches.connection_id && otherBranches.branch_id"
                                :active="true"
                                :article="otherBranches.article"
                                :connection="otherBranches.connection_id"
                                :branch="otherBranches.branch_id"
                                :warehouse="otherBranches.warehouse_id" />   
                                
                            <kardex-component v-if="tabOptionSelected == 'get-kardex' && otherBranches.article && otherBranches.connection_id && otherBranches.branch_id"
                                :active="true"
                                :article="otherBranches.article"
                                :connection="otherBranches.connection_id"
                                :branch="otherBranches.branch_id"
                                :warehouse="purchaseData.almacen_Id"
                                :warehouses="warehouses"/>
                                
                                
                        </b-card>
                    </b-col>     
                </b-row>         
            </b-overlay>
        </b-modal>
    </b-overlay>
</template>
  
<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BInputGroup, BFormGroup, BFormCheckbox, BFormFile, BInputGroupPrepend, BFormInvalidFeedback,
    BCardText, BCard, BCardHeader, BCol, BRow, BImg, BMedia, BAvatar, BOverlay, BSpinner, BFormRadioGroup, BFormDatepicker, BTable, BTableLite, BTooltip,
    VBTooltip, VBToggle, BBreadcrumb, BPagination, BCollapse, BLink, BTabs,  BTab, BNavItem, BAlert, BIcon, BButtonToolbar, BButtonGroup,
} from "bootstrap-vue";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue"; // AG Grid Component

import infiniteScroll from 'vue-infinite-scroll'
import VueSticky from 'vue-sticky' // Es6 module
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import * as helper from '@/libs/helpers';
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "animate.css";
import moment from "moment";
import router from "@/router";
import store from "@/store"
import { VueGoodTable } from 'vue-good-table'
import PricesChanges from '@core/components/purchase-customizer/PricesChanges.vue';
import LastPurchases from '@core/components/purchase-customizer/LastPurchases.vue';
import KardexComponent from '@core/components/purchase-customizer/Kardex.vue';

import WareHousesProvider from "@/providers/WareHouses"
const WareHousesResource = new WareHousesProvider()

import JmrsRotacionesArtsProvider from "@/providers/JmrsRotacionesArts"
const JmrsRotacionesArtsResource = new JmrsRotacionesArtsProvider()

import SuppliersProvider from "@/providers/Suppliers";
const SuppliersResource = new SuppliersProvider();

import CatalogClassifiersProvider from "@/providers/CatalogClassifiers";
const CatalogClassifiersResource = new CatalogClassifiersProvider();

import PricesArticulosProvider from "@/providers/PricesArticles";
const PricesArticulosResource = new PricesArticulosProvider();

import PurchasesProvider from "@/providers/Purchases"
const PurchasesResource = new PurchasesProvider()

import KardexProvider from "@/providers/Kardex"
const KardexResource = new KardexProvider()

import CentralJmrsRotacionesArtsProvider from '@/providers/CentralJmrsRotacionesArts'
const CentralJmrsRotacionesArtsResource = new CentralJmrsRotacionesArtsProvider()

import BranchesProvider from "@/providers/Branches"
const BranchesResouce = new BranchesProvider()

import ArticlesProvider from "@/providers/Articles"
const ArticlesResource = new ArticlesProvider()

export default {
    name: 'PurchaseList',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BFormCheckbox,
        BFormFile,
        BInputGroupPrepend,
        BFormInvalidFeedback,
        BCardText,
        BCard,
        BCardHeader,
        BCol,
        BRow,
        BImg,
        BMedia,
        BAvatar,
        BOverlay,
        BSpinner,
        BFormRadioGroup,
        BFormDatepicker,
        BTable,
        BTableLite,
        BTooltip,
        VBTooltip,
        BBreadcrumb,
        BPagination,
        BCollapse,
        BLink,
        BNavItem,
        BTabs, 
        BTab,
        BAlert,
        BIcon,
        VueGoodTable,
        BButtonToolbar,
        BButtonGroup,

        //
        AgGridVue,
        vSelect,
        flatPickr,
        Cleave,
        VueSticky,
        VuePerfectScrollbar,
        ValidationProvider,
        ValidationObserver,
        BCardActions,

        //
        PricesChanges,
        LastPurchases,
        KardexComponent,
    },
    directives: {
        "b-modal": VBModal,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
        infiniteScroll,
    },
    data() {
        return {
            navbarHeight: 0,
            isMobile: false,
            // selectedRowIndex: null,
            focusedRow: null,
            doubleClickFlag: false,
            clickTimeout: null,
            lastClicked: null, 
            isFixed: true,    
            currentPage: 1,
            itemsPerPage: 100,
            items: [],
            lastLoadedIndex: 0,
            totalData: 0,
            busy: false,
            searchQuery: null,
            tableTopOffset: '0px',
            modalSize: 'xl',
        }
    },
    created() {
        flatpickr.localize(Spanish);
        localize('es')
        store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', false)
        store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', false)
        store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', false)
        store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
        store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', null)
    },
    computed: {
        // Calcula el número total de páginas
        pageCount() {
            return Math.ceil(this.filterDataGeneal.length / this.generalPerPage);
        },
        // Obtiene los datos de la página actual
        currentPageData() {
            const startIndex = this.generlCurrentPage * this.generalPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.filterDataGeneal.slice(startIndex, endIndex);
        },

        // Calcula el número del primer registro en la página actual
        generalFrom() {
            return (this.generlCurrentPage - 1) * this.itemsPerPage + 1;
        },
        // Calcula el número del último registro en la página actual
        generalTo() {
            const end = this.generlCurrentPage * this.itemsPerPage;
            return Math.min(end, this.filterDataGeneal.length);
        },

        canUpdatePrice() {
            //Habilitar Cambio de Precios
            return this.canAccess(8);
        },
        isEnabledButtonSavePrices() {
            return this.canAccess(11) && this.isControlTotal;
        },
        isControlTotal() {
           return store.getters['auth/getConexion']?.control_Total || false;
        },
        isRecalculateRotations() {
            return store.getters['auth/getIsRecalculateRotations']
        },
        lastUpdateRotations () {
            return store.getters['auth/getLastUpdatePurchase']
        },
        isEndRecalculation () {
            return store.getters['auth/getIsRecalculateRotationEnd']
        },
        isErrorOnRecalculationRotation() {
            return store.getters['auth/getIsErrorOnRecalculateRotation']
        },
    },
    mounted() {
        // window.addEventListener('scroll', this.handleScroll);        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        // Detectar el ancho de la pantalla al cargar la página
        this.checkScreenSize();
        // Agregar un listener para detectar cambios en el tamaño de la ventana
        window.addEventListener('resize', this.checkScreenSize);

        const tableContainer = this.$refs.tableContainer;
        tableContainer.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        // window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', null)

        // Eliminar el listener del evento 'resize' al destruir el componente
        window.removeEventListener('resize', this.checkScreenSize);

        const tableContainer = this.$refs.tableContainer;
        tableContainer.removeEventListener('scroll', this.handleScroll);

        //Setear de nuevo el almacen seleccionado
        store.dispatch('auth/UPDATE_WAREHOUSE_ID', 0)
    },
    beforeUnmount() {
        store.dispatch('auth/UPDATE_WAREHOUSE_ID', null)
    },
    methods: { 
        onGeneralFiltered() {
            this.generlCurrentPage = 1;
        },
        // Navega a la página anterior
        previousPage() {
        if (this.generlCurrentPage > 0) {
            this.generlCurrentPage--;
        }
        },
        // Navega a la página siguiente
        nextPage() {
            if (this.generlCurrentPage < this.pageCount - 1) {
                this.generlCurrentPage++;
            }
        },
        getTableContent(table) {
            // Obtener todas las filas de la tabla
            const rows = Array.from(table.querySelectorAll('tr'));

            // Mapear cada fila a una cadena de texto separada por tabulaciones
            const tableContent = rows
                .map(row => {
                // Obtener todas las celdas de la fila
                const cells = Array.from(row.querySelectorAll('td, th'));
                // Mapear cada celda a su contenido de texto y unirlos con tabulaciones
                return cells.map(cell => cell.textContent).join('\t');
                })
                .join('\n'); // Unir las filas con saltos de línea

            return tableContent;
        },
        async copyTableFilters() {
            
            // // Obtener la tabla por su ref
            const table = this.actionTabGeneral ? this.$refs.refArticleGeneralListTable : this.$refs.refPurchasesListTable            

            // Verificar si el componente b-table se ha definido correctamente
            if (!table || !table.localItems) {
                console.error('El componente b-table no se ha encontrado o no tiene datos.');
                return;
            }

            // Obtener los datos de la tabla y los campos
            const tableData = table.localItems;
            const tableFields = table.computedFields;

            // Formatear los datos y los encabezados de la tabla como texto
            const tableContent = this.formatTableData(tableData, tableFields);


            // Copiar el contenido de la tabla al portapapeles
            this.copyToClipboard(tableContent);
            
            // Notificar al usuario
            this.success('¡Tabla copiada al portapapeles!');
        },
        formatTableData(data, fields) {
            // Formatear los encabezados de las columnas
            const headerRow = fields.map(field => field.label).join('\t');

            // Formatear los datos de la tabla como texto
            const dataRows = data.map(row => fields.map(field => row[field.key]).join('\t')).join('\n');

            // Unir encabezados y datos
            return `${headerRow}\n${dataRows}`;
        },
        copyToClipboard(text) {
            // Crear un elemento de texto temporal y copiar el contenido al portapapeles
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = text;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
        },
        getNextRow() {
            const currentIndex = this.refPurchasesListTableIndex;
            const maxIndex = this.filteredPurchaseList.length - 1;
            if (currentIndex < maxIndex) {
                return this.filteredPurchaseList[currentIndex + 1];
            }
            return null;
        },

        async handleNextRow() {
            const nextRow = this.getNextRow();
            if (nextRow) {

                this.kardexes = []
                this.lastPurchases = []

                console.log(nextRow)

                this.article = { ...nextRow, ultima_Compra: nextRow.ultimo_Costo };
                
                
                // await this.getPricesArticlesByArticleId(this.article.articulo_Id)
                this.selectedRow = nextRow;
                this.refPurchasesListTableIndex = this.filteredPurchaseList.indexOf(nextRow);
                this.refPurchasesListTable.selectRow(this.refPurchasesListTableIndex);

                //Promise all
                await Promise.all([
                    this.getPricesArticlesByArticleId(this.article.articulo_Id),
                    this.getLastPurcases(),
                    this.getKardex(this.article.articulo_Id)
                ]);

                //
                if (this.otherBranches.connection_id && this.otherBranches.branch_id && this.otherBranches.article) {
                    this.otherBranches.article = null
                    await this.getArticleByConnectionAndId()
                }
            }
        },

        //
        getPreviousRow() {
            const currentIndex = this.refPurchasesListTableIndex;
            if (currentIndex > 0) {
                return this.filteredPurchaseList[currentIndex - 1];
            }
            return null;
        },
        async handlePreviousRow() {
            const previousRow = this.getPreviousRow();
            if (previousRow) {

                this.kardexes = []
                this.lastPurchases = []

                this.article = { ...previousRow, ultima_Compra: previousRow.ultimo_Costo};                
                this.selectedRow = previousRow;
                this.refPurchasesListTableIndex = this.filteredPurchaseList.indexOf(previousRow);
                this.refPurchasesListTable.selectRow(this.refPurchasesListTableIndex);

                //Promise all
                await Promise.all([
                    this.getPricesArticlesByArticleId(this.article.articulo_Id),
                    this.getLastPurcases(),
                    this.getKardex(this.article.articulo_Id)
                ]);

                if (this.otherBranches.connection_id && this.otherBranches.branch_id && this.otherBranches.article) {
                    this.otherBranches.article = null
                    await this.getArticleByConnectionAndId()
                }

            } else {
                this.selectedRow = null
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            
            // Verificar si el ítem coincide con la fila seleccionada
            if (this.selectedRow && this.selectedRow.article_Id == item.article_Id) {
                return 'bg-primary';
            }
            
            // Si no hay fila seleccionada o el ítem no coincide, no se aplica ninguna clase
            return '';
        },
        checkScreenSize() {
            // Obtener el ancho de la pantalla
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            // Condición: si el ancho de la pantalla es mayor a 1920px, cambiar el tamaño del modal a 'lg'
            if (screenWidth > 1920) {
                this.modalSize = 'lg';
            } else {
                this.modalSize = 'lg'; // Tamaño predeterminado
            }

            console.log(this.modalSize)

            
        },
        setTabOptions(option = 'change-prices') {
            this.tabOptionSelected = option
            switch (option) {
                case 'change-prices':
                    break;
                case 'get-purchases':
                        this.getLastPurcases()
                    break;
                case 'get-kardex':
                    this.getKardex(this.article.articulo_Id)
                    break;            
                default:
                    this.tabOptionSelected = 'change-prices'
                    break;
            }
        },
        async getArticleByConnectionAndId() {
            if (!this.otherBranches.article) {
                try {
                    this.loadingModal = true
                    const { data } = await ArticlesResource.PorConexionYArticuloId(this.otherBranches.connection_id, this.article.articulo_Id)
                    this.loadingModal = false

                    if (data.isSuccesful) {
                        this.otherBranches.article = data.data
                    } else {
                        this.otherBranches.article = null
                    }            

                }catch(e) {
                    this.loadingModal = false
                    this.handleResponseErrors(e)
                }
            }
        },
        async searchByTabOptions() {

            //Get article name by equivalent
            await this.getArticleByConnectionAndId()

            if (this.tabOptionSelected === 'change-prices') {
                
                try {
                    this.loadingModal = true
                    const { data } = await PricesArticulosResource.GetPreciosArticulosByConnectionAndArticuloId(this.otherBranches.connection_id, this.article.articulo_Id, this.otherBranches.branch_id)
                    this.loadingModal = false

                    if (data.isSuccesful && data.data){
                        this.otherBranches.listPricesMessage = data.message
                        // this.otherBranches.listPrices = data.data.map(item => ({...item, name: item.lista, selected: false}))

                        this.otherBranches.listPrices = data.data.map(item => ({
                            selected: false,
                            price_company_id: item.precio_Empresa_Id,
                            name: item.lista,
                            cobrador_id: 0,
                            articulo_id: 0,
                            margin: 0,
                            price: item.precio
                        }))

                        console.log(this.otherBranches)
                    } else {
                        this.danger(data.message)
                    }

                }catch(e) {
                    this.loadingModal = false
                    this.handleResponseErrors(e)
                }
            }
        },
        async loadMoreData () {
            console.log(this.lastLoadedIndex)
            if (this.loading || this.lastLoadedIndex === this.totalData) return;
            this.loading = true;

            const startIndex = this.lastLoadedIndex;
            const endIndex = Math.min(startIndex + 100, this.totalData);

            // Simular la carga de nuevos datos
            const newData = this.articleAllList.slice(startIndex, endIndex);
            this.items.push(...newData);
            this.loading = false;
            this.lastLoadedIndex = endIndex;
        },
        fetchData() {
            // Simula una solicitud de datos al servidor
            return new Promise(resolve => {
                setTimeout(() => {
                const newData = this.generateData(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage);
                resolve(newData);
                }, 1000);
            });
        },
        generateData(start, end) {
            const data = [];
            for (let i = start; i < end; i++) {
                data.push(i);
            }
            return data;
        },
        handleScroll() {

            // // Obtenemos la posición actual del scroll
            // const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            // // Puedes ajustar el valor de 300 a lo que consideres apropiado para tu diseño
            // if (scrollPosition > 300) {
            //     // Si el scroll supera cierta posición, quitamos la clase de posición fija
            //     this.isFixed = false;
            // } else {
            //     // Si el scroll está por debajo de cierta posición, añadimos la clase de posición fija
            //     this.isFixed = true;
            // }

            const tableContainer = this.$refs.tableContainer;
            const bottomOfTable = tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight - 10;
            if (bottomOfTable && !this.loading) {
                this.getGeneralArticles();
            }
        },
        setNewPrice(item) {
            const lastPrice = parseFloat(this.article.ultima_Compra)
            if (!isNaN(lastPrice) && lastPrice > 0) {
                this.priceLists.forEach((item) => {
                    const margin = (item.margin*1)
                    if (/*item.selected &&*/ margin > 0) {
                        const newPrice = (((margin / 100) + 1) * lastPrice).toFixed(2);
                        item.price = !isNaN(newPrice) ? parseFloat(newPrice) : item.price;
                    }
                });
            }
        },
        onKeyDownPurchaseList(event, index) {
            // const table = this.$refs.refPurchasesListTable;
            // const selectedRow = this.$refs.refPurchasesListTable.$el.querySelector(`.b-table tbody tr:nth-child(${this.selectedRowIndex + 1})`);
            // // table.moveDown()
            // if (selectedRow) {
            //     selectedRow.focus(); // Asegúrate de que la fila seleccionada esté enfocada
            // }
        },
        handleKeyDown(event) {
            console.warn(event)
            // if (this.selectedRowIndex !== null) {
            //     switch (event.key) {
            //     case 'ArrowUp':
            //         if (this.selectedRowIndex > 0) {
            //         this.selectedRowIndex--;
            //         }
            //         break;
            //     case 'ArrowDown':
            //         if (this.selectedRowIndex < this.purchaseList.length - 1) {
            //         this.selectedRowIndex++;
            //         }
            //         break;
            //     default:
            //         return;
            //     }
            //     // Hacer algo con la fila seleccionada (por ejemplo, desplazar la vista hacia la fila seleccionada)
            //     const selectedRow = this.$refs.refPurchasesListTable.$el.querySelector(`.b-table tbody tr:nth-child(${this.selectedRowIndex + 1})`);
            //     if (selectedRow) {
            //         selectedRow.focus(); // Asegúrate de que la fila seleccionada esté enfocada
            //     }
            // }
        },
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        },    
        async getKardex(idArticle) {
            if (this.kardexes.length <= 0) {
                try {
                    this.loadingModal = true
                    const { data } = await KardexResource.getKardex(idArticle, this.purchaseData.almacen_Id, this.branchId, this.perPage)
                    this.loadingModal = false
                    if (data.isSuccesful) {
                        this.kardexes = data.data
                    } else {
                        this.danger(data.message)
                    }
                }catch(e) {
                    this.loadingModal = false
                    this.handleResponseErrors(e)
                }           
            }
        },
        async lastPurchasesByProductId (row, index) {
            this.selectedRowIndex = index
            this.lastPurchases = []
            this.kardexes = []
            try {
                this.loading = true
                const { data } = await PurchasesResource.lastPurchasesByProductId(row.articulo_Id)
                this.loading = false

                if (data.isSuccesful) {
                    this.lastPurchases = data.data
                    //this.$set(row, '_showDetails', true)
                    //this.$set(this.lastPurchases, row.articulo_Id, data.data.map(item => ({ ...item })));
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }   
        },
        async getLastPurcases() {
            // if (this.lastPurchases.length <= 0) {
                try {
                    this.loadingModal = true
                    const { data } = await PurchasesResource.lastPurchasesByProductId(this.article.articulo_Id, this.type_document)
                    this.loadingModal = false

                    if (data.isSuccesful) {
                        this.lastPurchases = data.data                    
                    } else {
                        this.danger(data.message)
                    }
                }catch(e) {
                    this.loadingModal = false
                    this.handleResponseErrors(e)
                }
            // }
        },

        //@row-clicked
        async toggleDetails(row, index, event) {
            this.lastPurchases = []
            this.kardexes = []
        },

        //@row-dblclicked
        onRowDoubleClickedPurchaseList(row) {
            console.warn(row)
        },
        getDetailsForTable(articulo_Id) {
            return this.lastPurchases[articulo_Id] || [];
        },
        getKardexForTable(articulo_Id) {
            return this.kardexes[articulo_Id] || [];
        },
        cancelUpdatingCurrentData() {
            store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
            store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', false)
        },
        acceptUpdatingCurrentData() {
            this.$swal({
                title: '¿Estás seguro de continuar?',
                html: 'Los datos filtrados y/o capturados se van a reiniciar...',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', false)
                    store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
                    await this.getProducts()
                }
            })
        }
    },    
    setup(props, { emit }) {

        const actionTabGeneral = ref(false)
        const generalPerPage = ref(100)
        const generlCurrentPage = ref(1)
        const generalTotalArticles = ref(0)
        const refPurchasesListTable = ref(null);
        const refPurchasesListTableIndex = ref(0)
        const tabOptionSelected = ref('change-prices')
        const currenWarehouseId = ref(null);
        const perPage = ref(30)
        const perPageOptions = ref([
            { value: 0, label: 'Todas' },
            { value: 30, label: 30 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
            { value: 300, label: 300 },
            { value: 500, label: 500 },
            { value: 1000, label: 1000 },
        ]);
        
        const type_document = ref('R')
        const optionsFilters = [
            { value: 'by_provider', text: 'Proveedor' },
            { value: 'by_classification', text: 'Clasificación' },
        ]
        const optionsSupplierFilters = [
            { value: 'items_purchased_from', text: 'Arts Comprados Desde' },
            { value: 'items_purchased_list', text: 'Arts Lista Compras' },
        ]
        const optionFilterBy = ref(null)
        const optionSupplierFilterBy = ref(null)
        const loading = ref(false);
        const loadingModal = ref(false)
        const formRef = ref(null)
        const warehouses = ref([])
        const suppliers = ref([])
        const classifiers = ref([])
        const classifierValues = ref([])
        const purchaseList = ref([])
        const searchItem = ref(null)
        const searchItemTemp = ref(null)
        const isActiveModal = ref(false)
        const priceLists = ref([])
        const article = ref({
            ultima_Compra: 0,
            cobrador_id: 0,
            price_list: {
                ultima_Compra: 0,
            },
        })
        const lastPurchases = ref([])
        const kardexes = ref([])
        const setting = store.getters['auth/getSetting']
        const connectionID = store.getters['auth/getConexionId']  
        const selectedRowIndex = ref(null)    
        const isCollapseFilters = ref(false) 
        const orderCost = ref({
            subtotal: 0,
            tax: 0,            
            total: 0,
        })
        
        const currentConnectionId = store.getters['auth/getConexionId']
        const connections = store.getters['auth/getConnections'].filter(item => item.idEmpresa !== currentConnectionId && item.es_Remoto)
        const branches = ref([])
        const otherBranches = ref({
            connection_id: 0,
            branch_id: 0,
            warehouse_id: 0,
            article_Id: null,
            article: null,
            listPrices: [],
            listPricesMessage: null,
        })
        const selectedRow = ref(null)
        const buyFrom = moment().add(-6, 'months').format('YYYY-MM-DD')
        const branchID = store.getters['auth/getBranch'].sucursal_Id
        const articleAllList = ref([])
        const showLastPurchase = ref(false)
        const showKardex = ref(false)
        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        }

        const pageNumber = ref(1) // Número de página actual
        const pageSize = ref(100) // Número de elementos por página 

        //Order Cost
        const orderCostSubtotal = computed(() => {
            return purchaseList.value.reduce((carry, item) => {
                return carry + 1
            }, 0)
        })

        const wareHouseComputed = computed(() => {
            return store.getters['auth/getWarehouseId']
        })

        //Table purchases
        const columnPurchases = computed(() => {
            return store.getters['auth/getSettingPurchase'].columns.filter(c => c.visible === true)
        })
              
        const filteredPurchaseList = ref([]);
        const applySearch = async () => {
            const searchTerm = searchItemTemp.value.toLowerCase();
            searchItem.value = searchTerm
            if (actionTabGeneral.value) {
                pageNumber.value = 1
                await getGeneralArticles()
            } else {
                if (!searchTerm) {
                    // Si no hay término de búsqueda, muestra todos los datos
                    filteredPurchaseList.value = [...purchaseList.value];
                } else {
                    // Aplica el filtro
                    filteredPurchaseList.value = purchaseList.value.filter(item =>
                        item.clave_Articulo?.toLowerCase().includes(searchTerm) ||
                        item.unidad_Venta?.toLowerCase().includes(searchTerm) ||
                        item.nombre.toLowerCase().includes(searchTerm)
                    );
                }
            }
        };

        const clearSearch = () => {
            searchItemTemp.value = '';
            searchItem.value = '';

            loading.value = true
            setTimeout(() => {                
                applySearch();                    
            }, 100);
            loading.value = false

            
        }

        const filterDataGeneal = computed(() => {
            
            if (!searchItem.value) return articleAllList.value;

            const searchTerm = searchItem.value.toLowerCase();
            return articleAllList.value.filter(item =>
                item.clave_Articulo?.toLowerCase().includes(searchTerm) ||
                item.unidad_Venta?.toLowerCase().includes(searchTerm) ||
                item.nombre.toLowerCase().includes(searchTerm)
            );
            
            let result = searchItem.value ? articleAllList.value.filter(item =>
                item.clave_Articulo?.toLowerCase().includes(searchItem.value.toLowerCase()) ||
                item.unidad_Venta?.toLowerCase().includes(searchItem.value.toLowerCase()) ||
                item.nombre.toLowerCase().includes(searchItem.value.toLowerCase())
            ) : articleAllList.value;
            return result;
        })
      
        //Table last purchases
        const columnLastPurchases = [
            {
                key: 'proveedor',
                label: 'Proveedor',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'recepcion',
                label: 'Recepción',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'fecha',
                label: 'Fecha',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costo',
                label: 'Costo',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costoReal',
                label: 'Costo Real',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'unidades',
                label: 'Unidades',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },

        ]

        //Table Kardex
        const columnKardex = [
            {
                key: 'fecha',
                label: 'Fecha',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'concepto',
                label: 'Concepto',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'folio',
                label: 'Folio',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'descripcion',
                label: 'Descripción',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'unidades',
                label: 'Unidades',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'inventario',
                label: 'InvFinal',
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
        ]

        const branchId = computed(() => {
            return store.getters['auth/getBranch'].sucursal_Id
        })

        const blankPurchaseData = {
            almacen_Id: null,
            lastupdate: null,
            classifier_Id: null,
            valor_clasif_id: null,
            supplier_id: null,
            order_cost: {
                tax_percentage: 16,
                subtotal: 0,
                tax_total: 0,
                total: 0,
            },
            filterBy: optionFilterBy.value,
            paramsBy: optionSupplierFilterBy.value,
            dateFrom: buyFrom,
            sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
        };

        const purchaseData = ref(JSON.parse(JSON.stringify(blankPurchaseData)));
        const resetPurchaseData = () => {
            purchaseData.value = JSON.parse(JSON.stringify(blankPurchaseData));
        };

        const selectWarehouseOnDetail = async (event) => {

            console.log(currenWarehouseId.value)

            kardexes.value = []
            try {
                loadingModal.value = true
                const { data } = await KardexResource.getKardex(article.value.articulo_Id, currenWarehouseId.value, branchId.value, perPage.value)
                loadingModal.value = false
                if (data.isSuccesful) {
                    kardexes.value = data.data
                } else {
                    helper.danger(data.message)
                }
            }catch(e) {
                loadingModal.value = false
                helper.handleResponseErrors(e)
            }    
        }

        const getGeneralArticles = async () => {

            const setting = store.getters['auth/getSetting']
            actionTabGeneral.value = true
            // if (articleAllList.value.length > 0) return false

            if(pageNumber.value === 1) {
                articleAllList.value = []
            }           

            const queryString = {
                idAlmacen: purchaseData.value.almacen_Id,
                idSucursal: store.getters['auth/getBranch'].sucursal_Id,
                rolClaveArtId: setting?.ROL_CLAVE_PRINCIPAL_ID || 17,
                pageNumber: pageNumber.value,
                pageSize: pageSize.value,
                search: searchItem.value,
            }

            try {
                loading.value = true
                const { data } = await PurchasesResource.ObtenerArticulosRotaciones(queryString) //getAllArticulos(queryString)
                loading.value = false

                if (data.isSuccesful) {
                    // articleAllList.value = data.data
                    // generalTotalArticles.value = data.data.length

                    articleAllList.value.push(...data.data);

                    //Ordenar por nombre de artículo y clave de artículo
                    articleAllList.value = _.orderBy(articleAllList.value, ['clave_Articulo'], ['ASC'])

                    // Incrementar el número de página
                    pageNumber.value += 1;
                } else {
                    helper.danger(data.message)
                }
            }catch(e) {
                loading.value = false
                helper.handleResponseErrors(e)
            }
           
        }

        const getProducts = async () => {

            purchaseList.value = []
            filteredPurchaseList.value = []
            lastPurchases.value = []
            kardexes.value = []

            if (!optionFilterBy.value) {
                helper.danger('Debe elegir un filtro por *Proveedor* ó *Clasificación*')
                return false                
            }

            if (optionFilterBy.value === 'by_provider') {
                if (!optionSupplierFilterBy.value) {
                    helper.danger(`Debe seleccionar un filtro en la opción de *Parámetros*`)
                    return false
                }

                if (!purchaseData.value.supplier_id) {
                    helper.danger(`Debe elegir un proveedor`)
                    return false
                }
            } else {
                if (!purchaseData.value.classifier_Id) {
                    helper.danger(`Debe elegir una clasificación`)
                    return false
                }

                if (!purchaseData.value.valor_clasif_id) {
                    helper.danger(`Debe elegir un valor de la clasificación`)
                    return false
                }


            }

            purchaseData.value.filterBy = optionFilterBy.value
            purchaseData.value.paramsBy = optionSupplierFilterBy.value
            const setting = store.getters['auth/getSetting']

            const queryString = {
                idAlmacen: purchaseData.value.almacen_Id,
                idSucursal: store.getters['auth/getBranch'].sucursal_Id,
                rolClaveArtId: setting?.ROL_CLAVE_PRINCIPAL_ID || 17,
                idProveedor: purchaseData.value.supplier_id,
                dateFrom: purchaseData.value.dateFrom,
                filterBy: purchaseData.value.filterBy ? purchaseData.value.filterBy : null,
                paramsBy: purchaseData.value.paramsBy ? purchaseData.value.paramsBy : null,
                idClasificacion: purchaseData.value.classifier_Id ? purchaseData.value.classifier_Id : 0,
                idClasificacionValor: purchaseData.value.valor_clasif_id ? purchaseData.value.valor_clasif_id : 0,
            }

            try {
                loading.value = true
                const { data } = await PurchasesResource.getArticulos(queryString) //CentralJmrsRotacionesArtsResource.getCentralJmrsRotacionesArts(connectionID, purchaseData.value.almacen_Id)
                nextTick(() => {
                    purchaseList.value = _.orderBy(data.data, ['clave_Articulo'], ['ASC'])

                    purchaseList.value.forEach(item => {
                        const rotacionPv = parseFloat(item.rotacion_Pv)
                        const rotacionVe = parseFloat(item.rotacion_Ve)
                        const existencia = parseFloat(item.existencia_Almacen)
                        const totalSum = rotacionPv + rotacionVe
                        item.total_meses = totalSum > 0 ? existencia / totalSum : 0
                    });

                    filteredPurchaseList.value = [...purchaseList.value]

                    loading.value = false
                    isCollapseFilters.value = true
                })
                
                
            } catch (error) {
                helper.danger(error)
                loading.value = false
            }
        }

        const getWareHouses = async () => {
            warehouses.value = store.getters['auth/getWareHouses']
            if (!warehouses.value) {
                loading.value = true
                const { data } = await WareHousesResource.index()
                loading.value = false
                warehouses.value = data.data
            }
        }

        const getSuppliers = async () => {
            loading.value = true
            const { data } = await SuppliersResource.index()
            loading.value = false
            suppliers.value = data.data
        }

        const getJmrsRotacionesArts = async () => {
            loading.value = true
            const { data } = await JmrsRotacionesArtsResource.index()
            loading.value = false
        }

        const getClassifiers = async () => {
            loading.value = true
            const { data } = await CatalogClassifiersResource.index()
            loading.value = false
            classifiers.value = data.data
        }

        const selectClassifier = async (event) => {
            classifierValues.value = []
            purchaseData.value.valor_clasif_id = null
            loading.value = true
            const { data } = await CatalogClassifiersResource.findValuesByClassifierId(event)
            loading.value = false
            classifierValues.value = data.data
        }

        const selectConnection = async () => { 
            branches.value = []
            otherBranches.value.branch_id = null

            try {
                loadingModal.value = true
                const { data } = await BranchesResouce.index(otherBranches.value.connection_id)
                loadingModal.value = false
                if (data.isSuccesful) {
                    branches.value = data.data
                } else {
                    helper.danger(data.message)
                }                
            }catch(e) {
                loadingModal.value = false                
                helper.handleResponseErrors(e)
            }
        }

        const selectBranch = async () => {
            otherBranches.value.article_Id = article.value.articulo_Id
            console.log(otherBranches.value)
        }

        // const getKardex = async (idArticle) => {
        //     loading.value = true
        //     const { data } = await KardexResource.getKardex(idArticle, purchaseData.value.almacen_Id)
        //     loading.value = false
        //     kardexes.value = data.data
        //     console.log(kardexes.value)
        // }


        /**Obtener ultima fecha de actualizacion de la tabla de jmrs_rotaciones_arts*/
        const selectWarehouse = async (event) => {
            store.dispatch('auth/UPDATE_WAREHOUSE_ID', event)
            currenWarehouseId.value = event
            loading.value = true
            const { data } = await CentralJmrsRotacionesArtsResource.GetCentralJmrsRotacionesArtsByAlmaceIdAndSucursal(event, branchID); //JmrsRotacionesArtsResource.findByAlmacenId(event)
            loading.value = false            
            if (data.isSuccesful) {                
                if(data.data && data.data.fecha_Actualizacion) {
                    purchaseData.value.lastupdate = data.data.fecha_Actualizacion
                    store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', purchaseData.value.lastupdate)
                } else {
                    purchaseData.value.lastupdate = '0000-00-00 00:00:00'
                    store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', '0000-00-00 00:00:00')    
                }                
            } else {
                purchaseData.value.lastupdate = '0000-00-00 00:00:00'
                store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', '0000-00-00 00:00:00')
            }
        }

        /** */
        const getPurchases = async () => {
            purchaseList.value = []
            try {
                loading.value = true
                const { data } = await JmrsRotacionesArtsResource.listPurchases(purchaseData.value.almacen_Id, setting.ROL_CLAVE_PRINCIPAL_ID)
                loading.value = false
                purchaseList.value = data.data
            } catch (error) {
                helper.danger(error)
                loading.value = false
            }
        }

        const exportToExcel = () => {
            loading.value = true
            JmrsRotacionesArtsResource.exportPurchases(purchaseData.value.almacen_Id, 17).then(response => {
                loading.value = false
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Compras-${moment.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                loading.value = false
            })
        }

        const onRowSelected = async (item, index) => {
            console.log(item)            
        }        

        const getPricesArticlesByArticleId = async (id) => {
            try {

                loadingModal.value = true
                const { data } = await PricesArticulosResource.findById(id)
                loadingModal.value = false

                if (data.isSuccesful) {
                    //article.value.ultima_Compra = data.data[0].ultima_Compra
                    priceLists.value = data.data.map(item => ({
                        selected: false,
                        price_company_id: item.precio_Empresa_Id,
                        name: item.lista,
                        cobrador_id: 0,
                        articulo_id: 0,
                        margin: 0,
                        price: item.precio
                    }))

                    calculateMargin()
                }
            }catch(error) {
                loadingModal.value = false
                helper.handleResponseErrors(error)
            }
        }

        const closeModal = () => {
            lastPurchases.value = []
            kardexes.value = []
            refPurchasesListTableIndex.value = -1
            isActiveModal.value = false
            otherBranches.value = {
                connection_id: 0,
                branch_id: 0,
                warehouse_id: 0,
                article_Id: null,
                article: null,
                listPrices: [],
                listPricesMessage: null,
            }
        }

        /**Obtener el detalle del articulo */
        const setArticle = (item, index) => {
            article.value = { ...item, ultima_Compra: item.ultimo_Costo }

            // Actualiza el índice de la fila seleccionada
            selectedRow.value = { ...item, ultima_Compra: item.ultimo_Costo };

            //Setear la variable con el index de la fila seleccionada
            refPurchasesListTableIndex.value = index

            //Seleccionar la fila
            refPurchasesListTable.value.selectRow(index)

            //Abrir el modal detalles
            isActiveModal.value = true
        }

        const calculatePrice = () => {
            const lastPrice = parseFloat(article.value.ultima_Compra)
            if (!isNaN(lastPrice) && lastPrice > 0) {
                priceLists.value.forEach((item) => {
                    if (/*item.selected &&*/ item.margin > 0) {
                        const newPrice = (((item.margin / 100) + 1) * lastPrice).toFixed(2);
                        item.price = !isNaN(newPrice) ? parseFloat(newPrice) : item.price;
                    }
                });
                
                // Actualizar la referencia de priceLists.values para que Vue detecte el cambio
                priceLists.value = [...priceLists.value];
            }
        }

        const calculateMargin = () => {
            const lastPrice = parseFloat(article.value.ultima_Compra);
            if (!isNaN(lastPrice) && lastPrice > 0) {
                priceLists.value.forEach((item) => {
                    if (!isNaN(item.price) && item.price > 0) {
                        const margin = ( ((item.price - lastPrice) / lastPrice) * 100).toFixed(2);
                        item.margin = margin
                    }
                })

                // Actualizar la referencia de priceLists.values para que Vue detecte el cambio
                priceLists.value = [...priceLists.value];
            }            
        }

        //Change prices by article
        const onSubmit = async () => {  
            
            // if (!article.value.cobrador_id) {
            //     helper.danger(`Debe ingresar la clave del cobrador`)
            //     return false
            // }
            
            const payload = {
                cobradorId: article.value.cobrador_id,
                listaPrecios: priceLists.value.filter(item => item.selected === true).map(item => ({
                    almacen_Id: purchaseData.value.almacen_Id,
                    empresa_Id: store.getters['auth/getConexionId'],
                    sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
                    precio_Empresa_Id: item.price_company_id,
                    articulo_Id: article.value.articulo_Id,
                    cobrador_Id: article.value.cobrador_id,
                    precio: item.price,
                    //ultimo_Costo: article.value.ultima_Compra
                }))
            }
            
            try {
                loading.value = true
                const { data } = await PricesArticulosResource.store(payload)
                loading.value = false
                if (data.isSuccesful) {
                    //isActiveModal.value = false
                    //priceLists.value = []
                    helper.success(data.message)
                } else {
                    helper.danger(data.message)
                }
            }catch(error) {
                loading.value = false
                console.log(error)
                //helper.handleResponseErrors(error)
            }
        }

        onMounted(async () => {
            await getWareHouses()
            await getSuppliers()
            await getClassifiers()
            // await getJmrsRotacionesArts()

            // nextTick(() => {
                if (warehouses.value.length > 0) {
                    purchaseData.value.almacen_Id = warehouses.value[0].almacen_Id
                    selectWarehouse(warehouses.value[0].almacen_Id)
                }
            // })
        })

        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetPurchaseData);


        watch([isActiveModal], async (value) => {
            if (value) {

                console.log(`active modal: ${value}`)
                try {

                    loadingModal.value = true
                    const { data } = await PricesArticulosResource.findById(article.value.articulo_Id)
                    loadingModal.value = false
                   
                    if (data.isSuccesful) {
                        //article.value.ultima_Compra = data.data[0].ultima_Compra
                        priceLists.value = data.data.map(item => ({
                            selected: false,
                            price_company_id: item.precio_Empresa_Id,
                            name: item.lista,
                            cobrador_id: 0,
                            articulo_id: 0,
                            margin: 0,
                            price: item.precio
                        }))

                        calculateMargin()
                    }
                }catch(error) {
                    loadingModal.value = false
                    helper.handleResponseErrors(error)
                }
            }
        })

        watch([optionFilterBy], (value) => {
            purchaseData.value.supplier_id = null
            // purchaseData.value.almacen_Id = null
            // purchaseData.value.lastupdate = null
            purchaseData.value.classifier_Id = null
            purchaseData.value.valor_clasif_id = null
            optionSupplierFilterBy.value = null
        }) 
        
        watch(searchItem, () => {
            filterDataGeneal.value = filterDataGeneal.value; // Force reactivity
        });

        return {
            actionTabGeneral,
            refPurchasesListTableIndex,
            perfectScrollbarSettings,
            optionsSupplierFilters,
            optionsFilters,
            optionFilterBy,
            optionSupplierFilterBy,
            buyFrom,
            loadingModal,
            loading,
            warehouses,
            purchaseData,
            formRef,
            suppliers,
            classifiers,
            classifierValues,
            columnPurchases,
            columnLastPurchases,
            columnKardex,
            purchaseList,
            searchItem,
            searchItemTemp,
            // filterData,
            applySearch,
            clearSearch,
            filteredPurchaseList,
            filterDataGeneal,
            isActiveModal,
            article,
            priceLists,
            lastPurchases,
            kardexes,
            selectedRowIndex,
            isCollapseFilters,
            connections,
            otherBranches,
            branches,
            articleAllList,
            type_document,
            tabOptionSelected,
            selectedRow,
            generalPerPage,
            generlCurrentPage,
            generalTotalArticles,
            
            //
            refFormObserver,
            getValidationState,
            resetForm,
            onSubmit,
            selectWarehouse,
            selectClassifier,
            getPurchases,
            exportToExcel,
            onRowSelected,
            closeModal,
            setArticle,
            calculatePrice,
            calculateMargin,
            // getKardex,
            showLastPurchase,
            showKardex,
            currenWarehouseId,
            perPage,
            perPageOptions,
            branchId,
            refPurchasesListTable,
            
            //
            selectWarehouseOnDetail,
            getProducts,
            selectConnection,
            selectBranch,
            getGeneralArticles,
            getPricesArticlesByArticleId,
        }

    }

}
</script>
  
<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-invoice.scss';

.table-active, .table-active > th, .table-active > td {
    background-color: #007bff !important;
    color: white;
}

.table-active > td > span > a {
    color: white;
}

// @media (min-width: 1200px) {
//     .modal-xl {
//         margin-left: 0%!important;
//         margin-right: 0%!important;
//     }
// }

// @media (min-width: 992px) {
//     .modal-lg {
//         max-width: 1200px!important;
//     }
// }

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 1200px!important;
        margin-left: 15%!important;
        margin-right: 10%!important;
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}

.text-truncate {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 150px;
}
</style>
  

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import "~@core/scss/base/pages/app-todo.scss";

.per-page-selector {
    width: 90px;
}

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.custom-card {
  position: sticky;
  top: 0; /* Fija los elementos en la parte superior del contenedor */
  height: 100vh; /* O ajusta la altura según sea necesario */
  overflow-y: auto; /* Añade un scroll vertical si el contenido excede la altura */
}

/*****Table container */
.table-container {
  overflow-x: auto; /* Permitirá el desplazamiento horizontal si la tabla es más ancha que el contenedor */
  max-height: 498px; /* Ajusta la altura máxima del contenedor según sea necesario */
}




/**** */
.card-body {
    padding: 1rem!important;
}

.position-fixed {
    position: fixed !important;
}

.fixed-right {
    right: 0;
}

.custom-table {
    font-size: 0.4rem; /* Ajusta el tamaño de fuente según sea necesario */
}

.b-table th {
    font-size: 0.4rem !important; /* Ajusta el tamaño de fuente según tu necesidad */
}

// .b-table-sticky-header {
    // overflow-y: auto;
    // max-height: 498px;
    // max-height: calc(100vh - 200px); /* Altura máxima del 100% de la altura de la ventana menos el espacio ocupado por el navbar y el footer */
// }

.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px; /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px!important; /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}

.my-custom-tabs .tab-pane {
  min-height: 200px; /* Establece una altura mínima para el contenido de las pestañas */
}

small, .my-small {
    font-size: 0.7rem!important;
    font-weight: 400;
}

.custom-modal .modal-dialog {
  max-width: 100%; /* Establecer el ancho máximo al 100% */
}

@media (min-width: 1200px) {
  .custom-modal .modal-dialog {
    max-width: 900px; /* Ancho máximo para pantallas grandes */
  }
}

.custom-lg-modal .modal-dialog {
  max-width: 1900px!important; /* Establecer el ancho máximo a 900px */
}

// @media (min-width: 1200px){
//     .modal-xl {
//         margin-left: 0px!important;
//         margin-right: 0px!important;
//     }
// }

// @media (min-width: 1200px){
//     .modal-xl {
//         max-width: 70%!important;
//     }
// }


</style>
