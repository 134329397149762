import axios from '@/libs/axios'
export default class CatalogClassifiers {

    /**
     * Function to Clasificadores_Cat
     * @return Promise
     */
    index() {
        return axios.get(`/ClasificadoresCat/ObtenerClasificadores`);
    }

    findValuesByClassifierId(id) {
        return axios.get(`/ClasificadoresCatValores/ObtenerClasificadoresCatValoresPorClasificadorId/${id}`)
    }
}