import axios from '@/libs/axios'

export default class Suppliers {

    /**
     * Function to branches
     * @return Promise
     */
    index() {
        return axios.get(`/Proveedores/ObtenerProveedores`);    
    }

    findById (id) {
        return axios.get(`/Proveedores/ObtenerProveedoresPorId/${id}`);
    }
}
