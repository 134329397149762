<template>
    <div>
        <b-row v-for="(item, index) in prices" :key="index">
            <b-col cols="12" md="4">
                <b-form-group :label="index === 0 ? 'Lista' : ''" :label-for="`price_${index+1}`">
                    <b-form-checkbox disabled v-model="item.selected" :id="`price_${index+1}`" class="mt-1">
                        <small>{{ item.name }}</small>
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group :label="index === 0 ? 'Margen' : ''" :label-for="`margin_${index+1}`">
                    <b-form-input readonly size="sm" v-model="item.margin" :id="`margin_${index+1}`" type="number" step="any" class="mb-2" />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">                                
                <b-form-group :label="index===0 ? 'Precio' : ''" :label-for="`price_${index+1}`">
                    <b-form-input readonly size="sm" v-model="item.price" :id="`price_${index+1}`" type="number" step="any" class="mb-2" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import { BButton, BModal, VBModal, BForm, BFormInput, BInputGroup, BFormGroup, BFormCheckbox, BFormFile, BInputGroupPrepend, BFormInvalidFeedback,
    BCardText, BCard, BCardHeader, BCol, BRow, BImg, BMedia, BAvatar, BOverlay, BSpinner, BFormRadioGroup, BFormDatepicker, BTable, BTableLite, BTooltip,
    VBTooltip, VBToggle, BBreadcrumb, BPagination, BCollapse, BLink, BTabs,  BTab, BNavItem, BAlert, BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, computed, onMounted, watch, reactive, defineEmits } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers'

import PricesArticulosProvider from "@/providers/PricesArticles";
const PricesArticulosResource = new PricesArticulosProvider();

export default {
    name: 'PricesChanges',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BFormCheckbox,
        BFormFile,
        BInputGroupPrepend,
        BFormInvalidFeedback,
        BCardText,
        BCard,
        BCardHeader,
        BCol,
        BRow,
        BImg,
        BMedia,
        BAvatar,
        BOverlay,
        BSpinner,
        BFormRadioGroup,
        BFormDatepicker,
        BTable,
        BTableLite,
        BTooltip,
        VBTooltip,
        BBreadcrumb,
        BPagination,
        BCollapse,
        BLink,
        BNavItem,
        BTabs, 
        BTab,
        BAlert,
        BIcon,

        //
       
    },
    directives: {
        "b-modal": VBModal,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        active: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        article: {
            type: Object,
            required: true,
            default: () => {}
        },
        connection: {
            type: Number,
            required: true,
            default: () => 0,
        },
        branch: {
            type: Number,
            required: false,
            default: () => 0,
        },
        warehouse: {
            type: Number,
            required: true,
            default: () => 0,
        },
        prices: {
            type: Array,
            required: true,
            default: () => [],
        },
        message: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    setup(props, { emit }) {
        
        const loading = ref(false)
        const listPriecs = ref([])

        // const getArticlePrices = async () => {
        //     loading.value = true
        //     const { data } = await PricesArticulosResource.findById(article.value.articulo_Id)
        //     loading.value = false
        // }


    },
}
</script>