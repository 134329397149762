import axios from '@/libs/axios'

export default class Kardex {

    getKardex(idArticle, idWarehouse, idSucursal, perPage) {
        return axios.get(`/kardex/ObtenerKardex/${idArticle}/${idWarehouse}/${idSucursal}/${perPage}`)
    }

    getKardexByConnection(idEmpresa, idArticle, idWarehouse, idSucursal, perPage) {
        return axios.get(`/kardex/ObtenerKardexPorConexion/${idEmpresa}/${idArticle}/${idWarehouse}/${idSucursal}/${perPage}`)
    }
}
