import axios from '@/libs/axios'

export default class Purchases {

    lastPurchasesByProductId(id, typeDocument) {
        return axios.get(`/Compras/ObtenerUltimaCompra/${id}/${typeDocument}`)    
    }

    getArticulos(query) {
        return axios.get(`/Compras/ObtenerArticulos`, {params: query})    
    }

    getAllArticulos(query) {
        return axios.get(`/Compras/ObtenerArticulosGeneral`, {params: query})    
    }

    ObtenerUltimaCompraPorConexion(idEmpresa, idArticle, typeDocument) {
        return axios.get(`/Compras/ObtenerUltimaCompraPorConexion/${idEmpresa}/${idArticle}/${typeDocument}`)    
    }

    ObtenerArticulosRotaciones(query) {
        return axios.get(`/Compras/ObtenerArticulosRotaciones`, {params: query})    
    }
}
